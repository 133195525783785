import * as React from "react"
import Layout from "../components/layout";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {defineCustomElements as deckDeckGoHighlightElement} from "@deckdeckgo/highlight-code/dist/loader";
import {graphql, Link, useStaticQuery} from "gatsby";
import Seo from "../components/Seo"


const IndexPage = () => {
    deckDeckGoHighlightElement().then();
    const data = useStaticQuery(graphql`
        query{
            allMdx(sort: {fields: frontmatter___date, order: DESC}) {
                nodes {
                    frontmatter {
                        title
                        description
                        label
                        date(formatString: "D MMMM YYYY")
                        hero_image {
                            childImageSharp {
                                gatsbyImageData(width: 700)
                            }
                            extension
                            publicURL
                        }
                        enevidence
                    }
                    slug
                }
            }
        }
    `)
    const latest = data.allMdx.nodes[0]
    let baliseimage
    if (latest.frontmatter.hero_image.extension !== "svg") {
        const image = getImage(latest.frontmatter.hero_image)
        baliseimage = <GatsbyImage
            image={image}
            alt={latest.frontmatter.hero_image_alt}
        />
    } else {
        baliseimage = <img src={latest.frontmatter.hero_image.publicURL} className="w-full align-middle"
                           alt={latest.frontmatter.hero_image_alt}/>
    }
    return (
        <Layout pageTitle="Le blog de Nicolas Singer">
            <Seo title="Home" />
            <div className="bg-blue-100 p-4">
                <div className="mt-8 lg flex md:flex-row flex-col text-left max-w-screen-lg mx-auto bg-white mb-8 items-center">
                    <div className="basis-2/5 mr-6 p-4">
                        <div className="mt-8 font-thin uppercase text-black/25">Dernier post</div>
                        <div className="mt-4 text-4xl italic text-black">{latest.frontmatter.title}</div>
                        <div className="mt-8 underline underline-offset-4 decoration-8 decoration-amber-500">
                            <Link to={"/blog/" + latest.slug} className="no-underline">
                                Suite de la lecture
                            </Link>
                        </div>
                        <div className="mt-4 text-1xl text-black">{latest.frontmatter.description}</div>
                    </div>
                    <div className="basis-3/5 p-4">
                        {baliseimage}
                    </div>
                </div>
            </div>
            <div className="p-4">
                <div className="font-bold text-4xl text-center mt-4 mb-8">En évidence</div>
                <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-8 max-w-screen-md mx-auto mt-4">
                    {
                        data.allMdx.nodes.filter(n => n.slug !== latest.slug && n.frontmatter.enevidence === "true").map(n => {
                                let baliseimage
                                baliseimage = <img src={n.frontmatter.hero_image.publicURL}
                                                   alt={n.frontmatter.hero_image_alt}
                                                   className="w-full h-64"
                                />
                                return (<div key={n.slug} className="mb-4">
                                    <Link to={"/blog/" + n.slug} className="no-underline visited:text-black group">
                                        {baliseimage}
                                        <div
                                            className="mt-2 font-thin uppercase text-black/25 text-base">{n.frontmatter.label}</div>
                                        <div
                                            className="group-hover:underline font-extrabold mt-2 text-2xl underline-offset-4 decoration-8 decoration-amber-500">{n.frontmatter.title}</div>
                                        <div className="mt-4 text-1xl text-black">{n.frontmatter.description}</div>
                                    </Link>
                                </div>)
                            }
                        )
                    }
                </div>
            </div>
            <div className="w-full p-4">
                <Link to={"/blog/"} className="no-underline visited:text-black group">
                    <div className="font-bold text-4xl text-center hover:underline underline-offset-4 decoration-8 decoration-amber-500">Tous les posts</div>
                </Link>
            </div>
        </Layout>
    )
}
export default IndexPage
